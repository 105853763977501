import React from "react";
import Helmet from "react-helmet";

import Layout from "src/layouts/LayoutWhiteHeader";
import config from "data/SiteConfig";
import ContactMeForm from "src/components/generic/ContactMeForm/ContactMeForm";

// eslint-disable-next-line react/prefer-stateless-function
class ContactMeForData extends React.Component {
	render() {
		return (
			<Layout>
				<main>
					<Helmet
						title={`Contact Me - Data for SEO | ${config.siteTitle}`}
					/>
				</main>
				<div className="container">
					<div className="columns">
						<div className="column is-half">
							<h2>Contact me</h2>
							<p>
								If you&#39;re interested in getting all your
								data into one place and want more information,
								fill in the form and I&#39;ll ping you an email
								so we can talk in more detail.
							</p>
							<p>And thank you!</p>
							<ContactMeForm formName="data-for-seo" />
						</div>
					</div>
				</div>
			</Layout>
		);
	}
}

export default ContactMeForData;
